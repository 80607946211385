import React from "react";

import "./copyright.styles.scss";
import { useTranslationHook } from "../../services/useTranslationHook";

function Copyright({ type }) {
  const t = useTranslationHook()
  return (
    <div className={`copyright ${type ? type : ""}`}>
      Copyright © {t("Wszelkie prawa zastrzeżone")} 2025 Marani Sp. z o.o.
    </div>
  );
}

export default Copyright;
